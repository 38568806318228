import api from '@/base/utils/request';

// 返回数据
export const getRule = () => {
    return api({
        url: '/admin/cyc/settings/getCycInviteMembersConfig',
        method: 'post'
    });
};
// 数据保存
export const setRule = data => {
    return api({
        url: '/admin/cyc/settings/setCycInviteMembersConfig',
        method: 'post',
        data
    });
};

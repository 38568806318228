<template>
  <div class="page-container" style="min-height: 400px" v-loading="loading">
    <reminder
      style="margin-bottom: 24px"
      class="reminder"
      title="温馨提示"
      :text-arr="tips"
    ></reminder>
    <el-form ref="form" label-width="110px" v-if="form">
      <div class="modular">
        <p class="modular-title">邀请权限</p>
        <div
          v-if="form.position_config"
          class="modular-content"
          style="margin-bottom: 40px; padding-left: 40px"
        >
          <el-checkbox-group v-model="authority_type">
            <!--              <el-checkbox v-for="au in authority" :key="au.id" :label="au.id"-->
            <!--                           :disabled="(form.position_config.type === 0 && au.id !== 0)-->
            <!--                           || authority_type.length === 1 && form.position_config.type !== au.id">{{au.name}}</el-checkbox>              -->
            <el-checkbox v-for="au in authority" :key="au.id" :label="au.id">{{
              au.name
            }}</el-checkbox>
          </el-checkbox-group>
          <el-cascader
            v-show="form.position_config.type === 0"
            style="width: 300px; margin-top: 20px"
            v-model="form.position_config.positions"
            :options="parentPositionList"
            :props="props"
            placeholder="请选择"
            :collapse-tags="true"
          >
          </el-cascader>
        </div>

        <p class="modular-title">审核配置</p>
        <div class="modular-content">
          <el-form-item label="入会审核：">
            <template>
              <div>
                <el-radio v-model="form.check_enable" :label="1"
                  >邀请的用户提交申请需要审核</el-radio
                >
              </div>
              <el-radio v-model="form.check_enable" :label="0"
                >邀请的用户提交申请无需审核</el-radio
              >
            </template>
          </el-form-item>
        </div>
      </div>
      <div class="modular">
        <p class="modular-title">收益配置</p>
        <div class="modular-content">
          <el-form-item label="收益方式：">
            <el-select v-model="form.type" placeholder="选择收益方式">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="invite-profit"
            v-if="form.type == 1"
            label="邀请好友收益："
          >
            <el-checkbox
              v-model="form.income_config_1.enable_money"
              label="现金 （元）"
              :disabled="!form.income_config_1.enable_integral"
            ></el-checkbox>
            <el-checkbox
              v-model="form.income_config_1.enable_integral"
              label="积分"
              :disabled="!form.income_config_1.enable_money"
            ></el-checkbox>
            <p style="margin-left: 35px">( 至少选中一个 )</p>
          </el-form-item>
          <el-form-item v-if="isProfit" label="直邀好友收益：">
            <div
              v-if="form.income_config_1.enable_money"
              style="margin-bottom: 30px"
            >
              1名好友
              <el-input-number
                v-model="form.income_config_1.each_first_money"
                :min="0"
              >
              </el-input-number>
              现金（元）
            </div>
            <div v-if="form.income_config_1.enable_integral">
              1名好友
              <el-input-number
                v-model="form.income_config_1.each_first_integral"
                :min="0"
              >
              </el-input-number>
              积分
            </div>
          </el-form-item>
          <el-form-item v-if="isProfit" label="扩散好友收益：">
            <div
              v-if="form.income_config_1.enable_money"
              style="margin-bottom: 30px"
            >
              1名好友
              <el-input-number
                v-model="form.income_config_1.each_second_money"
                :min="0"
              >
              </el-input-number>
              现金（元）
            </div>
            <div v-if="form.income_config_1.enable_integral">
              1名好友
              <el-input-number
                v-model="form.income_config_1.each_second_integral"
                :min="0"
              >
              </el-input-number>
              积分
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="modular">
        <p class="modular-title">规则配置</p>
        <div class="modular-content">
          <div class="custom-form">
            <el-form-item style="margin-bottom: 10px" label="收益规则：">
              <div class="radio-form">
                <el-radio v-model="form.cal_incomes_only_join" :label="1"
                  >成为正式会员后，才能获得收益奖励</el-radio
                >
                <el-radio v-model="form.cal_incomes_only_join" :label="0"
                  >通过入会审核后，即可获得收益奖励</el-radio
                >
              </div>
            </el-form-item>
            <el-form-item style="margin-bottom: 10px" label="入会规则：">
              <div class="radio-form">
                <el-radio v-model="form.join_only_from_first" :label="1"
                  >被邀请的用户只能申请加入对应的组织</el-radio
                >
                <el-radio v-model="form.join_only_from_first" :label="0"
                  >被邀请的用户可自行选择申请加入的组织</el-radio
                >
              </div>
            </el-form-item>
            <el-form-item style="margin-bottom: 10px" label="直邀规则：">
              <div class="radio-form">
                <el-radio v-model="form.first_income_only_once" :label="0"
                  >用户在多职务时允许被直邀多次</el-radio
                >
                <el-radio v-model="form.first_income_only_once" :label="1"
                  >用户只允许被直邀一次</el-radio
                >
              </div>
            </el-form-item>
            <el-form-item style="margin-bottom: 10px" label="扩散规则：">
              <div class="radio-form">
                <el-radio
                  v-model="form.second_income_only_from_first"
                  :label="-1"
                  >无收益</el-radio
                >
                <el-radio
                  v-model="form.second_income_only_from_first"
                  :label="1"
                  >扩散好友申请加入对应的组织才有收益</el-radio
                >
                <el-radio
                  v-model="form.second_income_only_from_first"
                  :label="0"
                  >扩散好友任意申请加入组织就有收益</el-radio
                >
              </div>
            </el-form-item>
            <!-- 只允许被直邀一次 -->
            <el-form-item
              v-if="form.first_income_only_once === 1"
              style="margin-bottom: 10px"
              label="已注册统计规则："
            >
              <div class="radio-form">
                <el-radio v-model="form.non_join_only_for_applying" :label="0"
                  >用户扫邀请码进入，登录后即统计</el-radio
                >
                <el-radio v-model="form.non_join_only_for_applying" :label="1"
                  >用户扫邀请码进入，登录并提交入会申请后才统计</el-radio
                >
              </div>
            </el-form-item>

            <div v-if="false">
              <p class="form-title">
                邀请入会收益规则
                <el-popover placement="top" trigger="hover">
                  <reminder
                    class="reminder"
                    title="规则说明"
                    :text-arr="tips_one"
                  ></reminder>
                  <span
                    slot="reference"
                    class="el-icon-info cursor-pointer"
                  ></span>
                </el-popover>
              </p>
              <el-table :data="form.incomes_rules" class="thead-light" border>
                <template v-for="(th, index) in thead">
                  <el-table-column
                    show-overflow-tooltip
                    :prop="th.prop"
                    :label="th.label"
                    :min-width="th.minWidth || ''"
                    :width="th.width || ''"
                    :key="index"
                    :sortable="th.sortable"
                  >
                    <template slot="header" slot-scope="scope">
                      <div v-if="th.prop === 'name'" class="first-header">
                        <p class="header-top">被邀请人选择加入</p>
                        <div class="diagonal-line"></div>
                        <p class="header-bottom">邀请加入</p>
                      </div>
                      <span v-else>{{ th.label }}</span>
                    </template>
                    <template slot-scope="scope">
                      <!-- 勾选 -->
                      <div v-if="th.prop !== 'name'">
                        <el-checkbox
                          :disabled="scope.row[th.prop] === -1"
                          :false-label="0"
                          :true-label="1"
                          v-model="scope.row[th.prop]"
                        >
                        </el-checkbox>
                      </div>
                      <!-- 组织名称 -->
                      <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
                    </template>
                  </el-table-column>
                </template>
              </el-table>
            </div>
            <div>
              <p class="form-title">
                被邀请人收益规则
                <el-popover placement="top" trigger="hover">
                  <reminder
                    class="reminder"
                    title="规则说明"
                    :text-arr="tips_two"
                  ></reminder>
                  <span
                    slot="reference"
                    class="el-icon-info cursor-pointer"
                  ></span>
                </el-popover>
              </p>
              <el-table :data="form.rules" class="thead-light" border>
                <template v-for="(th, index) in thead">
                  <el-table-column
                    show-overflow-tooltip
                    :prop="th.prop"
                    :label="th.label"
                    :min-width="th.minWidth || ''"
                    :width="th.width || ''"
                    :key="index"
                    :sortable="th.sortable"
                  >
                    <template slot="header" slot-scope="scope">
                      <div v-if="th.prop === 'name'" class="first-header">
                        <p class="header-top">被邀请加入</p>
                        <div class="diagonal-line"></div>
                        <p class="header-bottom">被邀请人已加入</p>
                      </div>
                      <span v-else>{{ th.label }}</span>
                    </template>
                    <template slot-scope="scope">
                      <!-- 勾选 -->
                      <div v-if="th.prop !== 'name'">
                        <el-checkbox
                          :disabled="scope.row[th.prop] === -1"
                          :false-label="0"
                          :true-label="1"
                          v-model="scope.row[th.prop]"
                        >
                        </el-checkbox>
                      </div>
                      <!-- 组织名称 -->
                      <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
                    </template>
                  </el-table-column>
                </template>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="modular">
        <p class="modular-title">邀请说明</p>
        <div class="modular-content">
          <el-form-item label="邀请说明：">
            <el-input
              type="textarea"
              v-model="form.description"
              placeholder="请输入邀请说明"
            ></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>

    <fixed-action-bar>
      <el-button type="primary" @click="saveConfig()">保存</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
import { getRule, setRule } from '../../invite-members/api/rule-list'
import Reminder from '../../common/components/Reminder'
import { positionLevel } from '@/modules/activity/api/sign-up-list/list'

export default {
  components: { FixedActionBar, Reminder },
  data() {
    return {
      loading: false,
      options: [
        {
          value: 0,
          label: '无',
        },
        {
          value: 1,
          label: '方式一',
        },
      ],
      thead: [
        { label: '', prop: 'name', width: 200 },
        { label: '一级组织', prop: 'to_first', minWidth: 100 },
        { label: '子组织', prop: 'to_sub', minWidth: 100 },
        { label: '其他一级组织', prop: 'to_other_first', minWidth: 120 },
        { label: '其他子组织', prop: 'to_other_sub', minWidth: 100 },
      ],
      form: {
        non_join_only_for_applying: 0,
      },
      tips: [
        '好友已被邀请过，后续被退会后，再次被邀请时，将不计算收益',
        '规则配置-入会规则 修改后，需要生成新的海报才能生效',
      ],
      tips_one: [
        '邀请加入一级组织，被邀请人加入一级组织，计算收益（默认选中，不可编辑）',
        '邀请加入一级组织，被邀请人加入子组织，计算收益（反之则不计算）',
        '邀请加入一级组织，被邀请人加入其他一级组织，计算收益（反之则不计算）',
        '邀请加入一级组织，被邀请人加入其他子组织，计算收益（反之则不计算）',
        '邀请加入子组织，被邀请人加入一级组织，计算收益（反之则不计算）',
        '邀请加入子组织，被邀请人加入子组织，计算收益（反之则不计算）',
        '邀请加入子组织，被邀请人加入其他一级组织，计算收益（反之则不计算）',
        '邀请加入子组织，被邀请人加入其他子组织，计算收益（反之则不计算）',
      ],
      tips_two: [
        '被邀请人已加入一级组织，随后被邀请加入已加入层级的子组织，计算收益（反之则不计算）',
        '被邀请人已加入一级组织，随后被邀请加入其他一级组织，计算收益（反之则不计算）',
        '被邀请人已加入一级组织，随后被邀请加入其他子组织，计算收益（反之则不计算）',
        '被邀请人已加入子组织，随后被邀请加入已加入层级的一级组织，计算收益（反之则不计算）',
        '被邀请人已加入子组织，随后被邀请加入已加入层级的子组织，计算收益（反之则不计算）',
        '被邀请人已加入子组织，随后被邀请加入其他一级组织，计算收益（反之则不计算）',
        '被邀请人已加入子组织，随后被邀请加入其他子组织，计算收益（反之则不计算）',
      ],
      authority: [
        {
          id: 0,
          name: '指定职务可邀请',
        },
        {
          id: 1,
          name: '正式会员可邀请',
        },
        // {
        //   id: 2,
        //   name: '全部用户可邀请',
        // },
      ],
      parentPositionList: [], // 组织职务选项数据
      // 联级选择配置
      props: {
        value: 'id',
        label: 'name',
        expandTrigger: 'hover',
        children: 'position',
        multiple: true,
        emitPath: false,
      },
    }
  },
  computed: {
    isProfit() {
      return (
        (this.form.income_config_1?.enable_money ||
          this.form.income_config_1?.enable_integral) &&
        this.form.type
      )
    },
    // 过渡变量
    authority_type: {
      get() {
        return this.form ? [this.form.position_config?.type] : []
      },
      set(val) {
        if (val) {
          if (val.length) {
            // 变成单选功能
            this.form.position_config.type = val[val.length - 1]
          }
        } else {
        }
      },
    },
  },
  created() {
    this.getRuleDetail()
    this.getPositionLevel()
  },
  methods: {
    // 获取架构职务
    getPositionLevel() {
      positionLevel({ hashid_enable: 0, is_list_tree: 0, is_show_all: 0 })
        .then((res) => {
          this.parentPositionList = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //获取规则配置详情
    getRuleDetail() {
      this.loading = true
      getRule()
        .then((res) => {
          this.form = res.data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    saveConfig() {
      this.loading = true
      setRule({ config: this.form })
        .then((res) => {
          this.$message.success(res.msg)
          this.getRuleDetail()
        })
        .catch((err) => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-container {
  ::v-deep.el-textarea__inner {
    height: 100px;
    width: 300px;
  }
  ::v-deep.el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: rgba(0, 0, 0, 0);
  }
  ::v-deep.is-leaf {
    text-align: center;
  }
  // 覆写checkbox disabled样式
  ::v-deep.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #3576ff;
    border-color: #3576ff;
  }
  ::v-deep.el-checkbox__input.is-disabled + span.el-checkbox__label {
    color: #3576ff;
  }
  ::v-deep.el-checkbox__inner::after {
    border-color: #fff !important;
  }
  ::v-deep.has-gutter {
    tr {
      :nth-child(1) {
        padding: 0;
        background-color: #f0f2f5 !important;
        .cell {
          padding: 0 !important;
          white-space: normal;
          word-break: break-all;
          line-height: 23px;
          font-weight: bold;
          color: #000;
        }
        .first-header {
          position: relative;
          height: 70px;
          width: 100%;
          .diagonal-line {
            position: absolute;
            height: 100%;
            width: 100%;
            background: linear-gradient(
              to top right,
              transparent,
              transparent 49.5%,
              #bebcbc,
              transparent 50.5%,
              transparent
            );
            left: 0;
            top: 0;
          }
          .header-top {
            position: absolute;
            right: 10px;
            top: 10px;
          }
          .header-bottom {
            position: absolute;
            left: 10px;
            bottom: 10px;
          }
        }
      }
    }
  }
  ::v-deep.el-table__row {
    td:first-child {
      background-color: #f0f2f5 !important;
      .cell {
        color: #000000;
        font-weight: bold;
      }
    }
    .cell {
      padding: 0 10px !important;
      text-align: center;
    }
  }
}

.el-form-item {
  margin-left: 11px;
}

.el-input-number {
  width: 200px;
  margin: 0 10px;
}
.thead-light {
  width: calc(100% - 11px);
  margin-left: 11px;
  margin-bottom: 32px;
}
.table-tip {
  margin: 20px 0 32px 11px;
  color: #9b9b9b;
  font-size: 14px;
  line-height: 14px;
}
.custom-form {
  .form-title {
    color: #606266;
    padding-bottom: 20px;
    margin-left: 11px;
    font-size: 14px;
    line-height: 14px;
  }
  .el-icon-info {
    margin-left: 4px;
    font-size: 15px;
  }
}

.reminder {
  margin-bottom: 0px;
}
.radio-form {
  display: flex;
  flex-direction: column;
  .el-radio {
    line-height: 40px;
  }
}
.invite-profit {
  ::v-deep.el-form-item__content {
    display: flex;
  }
}
</style>
